import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, image: metaImage, title }) {
    const {site, img} = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        description
                        author
                        keywords
                        siteUrl
                    }
                }
                img: file(relativePath: { eq: "memoji.png" }) {
                    childImageSharp {
                        fixed(width: 500) {
                            src
                            width
                            height
                        }
                    }
                }
            }
        `
    );

    const metaDescription = description || site.siteMetadata.description;
    const image = metaImage && metaImage.src ? metaImage : img.childImageSharp.fixed;
    const titleTemplate = title.length === 0 ? site.siteMetadata.title : `${title} | ${site.siteMetadata.title}`;

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={titleTemplate}
            meta={[
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    name: `keywords`,
                    content: site.siteMetadata.keywords.join(","),
                },
                {
                    property: `og:title`,
                    content: titleTemplate,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `twitter:creator`,
                    content: site.siteMetadata.author,
                },
                {
                    name: `twitter:title`,
                    content: titleTemplate,
                },
                {
                    name: `twitter:description`,
                    content: metaDescription,
                },
            ]
                .concat(
                    image
                        ? [
                            {
                                property: "og:image",
                                content: site.siteMetadata.siteUrl + image.src,
                            },
                            {
                                property: "og:image:width",
                                content: image.width,
                            },
                            {
                                property: "og:image:height",
                                content: image.height,
                            },
                            {
                                name: "twitter:card",
                                content: "summary_large_image",
                            },
                            {
                                name: "twitter:image:src",
                                content: site.siteMetadata.siteUrl + image.src
                            }
                        ] : [
                            {
                                name: "twitter:card",
                                content: "summary",
                            },
                        ]
                )
                .concat(meta)}
        />
    )
}
SEO.defaultProps = {
    lang: `en`,
    meta: [],
    description: ``,
}
SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
    image: PropTypes.shape({
        src: PropTypes.string.isRequired,
        height: PropTypes.number.isRequired,
        width: PropTypes.number.isRequired,
    }),
}
export default SEO

